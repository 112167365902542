<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 油站详情
 * @Date: 2020-11-06 15:46:26
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-11-22 21:08:54
 * @FilePath: /yue_quanzhan_h5_new/src/views/localLife/comeOn/comeOnDatainfo.vue
-->
<template>
  <div class="content">
    <div class="details_box">
      <div class="details_top">
        <div class="details_img">
          <img :src="info.logo" alt="">
        </div>
        <div class="details_text">
          <div class="text_title">{{ info.name }}</div>
          <div class="text_price">
            <div class="price_s"><span>¥</span><span>{{ priceYfq }}</span></div>
          </div>
          <div class="text_Buoy">
            <div v-for="(item,index) in tag" :key="index">
              <span>{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="details_bottom">
        <div><span /><span>{{ info.address }}</span></div>
        <div @click="Navigation">导航</div>
      </div>
    </div>
    <div class="commodity">
      <div class="commodity_title">选择商品</div>
      <ul>
        <li v-for="(item,index) in goods" :key="index" :class="goodkey === item.key?'active':''" @click="getgood(item)">
          {{ item.name }}
        </li>
      </ul>
      <div class="commodity_title">选择油号</div>
      <ul v-if="goodkey === 'qiyou'">
        <li
          v-for="(item,index) in attr.qiyou"
          :key="index"
          :class="name === item.name?'active':''"
          @click="qiyoux(item,index)"
        >{{ item.name }}
        </li>
      </ul>
      <ul v-if="goodkey === 'chaiyou'">
        <li
          v-for="(items,indexs) in attr.chaiyou"
          :key="indexs"
          :class="index_c === items.id?'active':''"
          @click="qiyoux(items,indexs)"
        >{{ items.name }}
        </li>
      </ul>
      <ul v-if="goodkey === 'xinnengyuan'">
        <li
          v-for="(itemss,indexss) in attr.xinnengyuan"
          :key="indexss"
          :class="index_x === itemss.id?'active':''"
          @click="qiyoux(itemss,indexss)"
        >{{ itemss.name }}
        </li>
      </ul>
      <div class="commodity_title">选择枪号</div>
      <ul>
        <li v-for="(item,index) in tool" :key="index" :class="index_g === item.id?'active':''" @click="gun(item,index)">
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="details_tips">
      <div class="tips_title">
        <van-icon name="warning-o" color="#F7263C" size="20" />
        重要提示
      </div>
      <p>
        请务必 <b>先到达油站</b> 与工作 与工作人员确认后再付款，切勿
        先买单后加油，避免异常订单的产生！
      </p>
      <p>若无您选择的油枪号，请联系油站工作人员 支付前请确认加油站是否正确</p>
    </div>
    <!--      地图-->
    <!-- <van-action-sheet
      v-model="show"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @select="onCancel"
    /> -->
    <div v-if="showimg" class="mask" @click="closeshow">
      <div class="picture-box">
        <img class="picture" src="@/assets/images/wxtwo.jpg">
        <div class="btn">长按图片保存到手机相册</div>
        <!-- <div class="btn" style="background: #ff7800">预览海报长按分享</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { ActionSheet, Icon, Toast } from 'vant'

Vue.use(Icon).use(Toast)
Vue.use(ActionSheet)
import { getXJComeOnDetail } from '@/services/comeOn.js'
import { closeShare } from '@/utils/wx-api'
import { isWeChat } from '@/utils/common/validate'

export default {

  data() {
    return {
      topclose: true,
      gasId: '', // 油站编号
      oilId: '', // 燃油编号
      type: '', // 燃油类型

      name: '',
      info: '',
      goods: [],
      attr: [],
      price: [],
      priceYfq: '',
      tag: [],
      index_c: '1',
      index_y: '1',
      index_g: '',
      tool: [],
      youhao: '',
      youhao2: '',
      oilType: '',
      priceGun: '',
      userinfo: '',
      goodkey: 'qiyou',
      phone: '',
      showimg: false,
      index_x: ''
    }
  },
  created() {
    this.gasId = this.$route.query ? this.$route.query.gasid : ''// 油站编号
    this.oilId = this.$route.query ? this.$route.query.oilId : ''// 燃油编号

    this.type = this.$route.query ? this.$route.query.type : ''// 燃油类型
    this.phone = localStorage.getItem('mobile')
    closeShare()// 禁用微信分享
  },
  mounted() {
    this.getComeOndetails()
  },
  methods: {
    isclose(t) {
      this.topclose = t
    },
    closeshow() {
      this.showimg = false
    },
    getshowimg(item) {
      this.showimg = item
    },
    // 导航
    Navigation() {
      if (isWeChat()) {
        window.location.href = 'http://apis.map.qq.com/uri/v1/marker?marker=coord:' + Number(this.info.lat) + ',' + Number(this.info.lng) + ';title:' + this.info.title + ';addr:' + this.info.address
      } else {
        return false
      }
    },
    // Navigation(){
    //   let _self = this;
    //   let ua = navigator.userAgent.toLowerCase();
    //   if (ua.match(/MicroMessenger/i) === "micromessenger") {
    //     window.location.href= 'http://apis.map.qq.com/uri/v1/marker?marker=coord:' + Number(_self.info.lat) + ',' + Number(_self.info.lng) + ';title:' + _self.info.name + ';addr:' +  _self.info.address;
    //   }else {
    //     return false;
    //   }
    // },
    // 详情数据
    getComeOndetails() {
      getXJComeOnDetail({
        gasId: this.gasId, // 油站标号
        mid: localStorage.getItem('mobile'),
        mobile: this.phone,
        type: this.type,
        oilId: this.oilId
      }).then(res => {
        if (Number(res.code) === 200) {
          this.info = res.data.info
          this.goods = res.data.goods
          this.attr = res.data.attr
          this.price = res.data.price
          this.goodkey = (this.goods)[0].key
          if ((this.goods)[0].key === 'xinnengyuan') {
            this.qiyoux(res.data.attr.xinnengyuan[0], 0)
          } else if ((this.goods)[0].key === 'qiyou') {
            this.qiyoux(res.data.attr.qiyou[0], 0)
          } else if ((this.goods)[0].key === 'chaiyou') {
            this.qiyoux(res.data.attr.chaiyou[0], 0)
          }
        }
      })
    },
    // 选择商品
    getgood(t) {
      this.goodkey = t.key
    },
    // 汽油号
    qiyoux(t, i) {
      this.other_gas_id = t.id// 油站id
      this.name = t.name// 汽油
      // this.name = this.oilId+'#';
      this.youhao = t.id
      this.oilType = t.oilType
      this.priceGun = t.priceGun
      this.index_c = i// 柴油
      this.priceYfq = t.priceYfq
      this.tag = t.tag
      this.tool = t.tool
      // 新能源
      this.index_x = t.id
    },
    // 选择枪号
    gun(t, i) {
      this.index_g = this.tool[i].id
      this.Submit()
    },
    //  提交
    Submit() {
      this.$router.push({
        path: '/AmountSubmitted',
        query: {
          'id': this.info.id,
          'gas_id': this.info.gasId, // 油id
          'gas_name': this.info.name, // 油名称
          'other_gas_id': this.other_gas_id, // 油站id
          'number': this.youhao, // 油站编号
          'name': this.oilId, // 燃油号
          'gun_no': this.index_g, // 枪号
          'oil_type': this.oilType, // 燃油类别 汽油，柴油，天然气
          'price_gun': this.priceGun, // 枪价
          'price_unit': this.priceYfq, // 优惠价
          'pricelist': this.price// 价格列表
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 99;

  .picture-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .picture {
      display: block;
      width: 280px;
      // height: 360px;
      background: rebeccapurple;
      margin: 0 auto;
    }

    .btn {
      width: 290px;
      height: 44px;
      border-radius: 22px;
      background: #ed2e2e;
      text-align: center;
      line-height: 44px;
      font-size: 16px;
      color: #fff;
      margin-top: 15px;
    }
  }
}

.content {
  padding: 10px;
  background-color: #f8f8f8;
  min-height: 100vh;

  .ophead {
    height: 40px;
  }

  .ophead.vs {
    transition: height 0.6s ease-in-out;
    height: 0;
    display: none;
  }

  .details_box {
    background-color: #ffffff;
    border-radius: 4px;
    padding: 10px;

    .details_top {
      display: flex;
      align-items: center;
      margin: 10px 0;

      .details_img {
        flex: 7;
        height: 77px;
        background-color: #333333;
        border-radius: 5px;

        & > img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }

      .details_text {
        flex: 16;
        margin: 0 10px;

        .text_title {
          color: #333333;
          font-size: 16px;
          text-align: left;
          font-weight: 600;
        }

        .text_price {
          color: #f7263c;
          display: flex;
          align-items: center;
          margin: 7px 0;

          .price_s {
            flex: 12;
            text-align: left;

            & > span:nth-child(1) {
              font-size: 12px;
            }

            & > span:nth-child(2) {
              font-size: 22px;
              font-weight: 500;
            }
          }

          .price_distance {
            flex: 12;
            text-align: right;
            color: #666666;
            font-size: 12px;
          }
        }

        .text_Buoy {
          display: flex;

          & > div {
            flex: 12;
            border: 1px solid #f7263c;
            margin-right: 5px;
            border-radius: 4px;
            color: #f7263c;
            display: flex;
            align-items: center;

            & > span:nth-child(1) {
              background-color: #ffebea;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
              font-size: 12px;
              padding: 3px;
              flex: 12;
            }

            & > span:nth-child(2) {
              font-size: 12px;
              flex: 12;
            }
          }
        }
      }
    }

    .details_bottom {
      display: flex;
      align-items: center;
      border-top: 1px solid #eeeeee;
      padding-top: 10px;

      & > div:nth-child(1) {
        flex: 17;
        margin-right: 6px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 25px;

        & > span:nth-child(1) {
          float: left;
          width: 25px;
          height: 25px;
          line-height: 25px;
          background-image: url("~@/assets/images/weizhi_2.png");
          background-repeat: no-repeat;
          background-size: 50% 60%;
          background-position: 50% 45%;
        }
      }

      & > div:nth-child(2) {
        flex: 6;
        background-color: #f7263c;
        color: #fff;
        padding: 10px;
        border-radius: 4px;
        font-size: 15px;
        background-image: url("~@/assets/images/weizhi_1.png");
        background-repeat: no-repeat;
        background-size: 14px auto;
        background-position: 18% 46%;
      }
    }
  }

  .commodity {
    background-color: #fff;
    border-radius: 4px;
    margin-top: 10px;
    padding: 10px;

    .commodity_title {
      color: #333333;
      text-align: left;
      font-size: 13px;
      margin: 10px 0;
    }

    & > ul {
      display: flex;
      flex-wrap: wrap;

      & > li {
        color: #999999;
        border: 1px solid #a8a8a8;
        padding: 7px;
        margin: 0 7px 10px 0;
        width: 20%;
        border-radius: 4px;
      }

      & > li.active {
        background-color: #f7263c;
        color: #fff;
        border: 1px solid #f7263c;
      }
    }
  }

  .details_tips {
    background-color: #ffebea;
    padding: 15px;
    color: #f7263c;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 30px;

    .tips_title {
      font-size: 15px;
      font-weight: 500;
      text-align: left;
      display: flex;
      align-items: center;
    }

    & > p {
      font-size: 12px;
      line-height: 20px;
      text-align: left;
      text-indent: 2em;

      & > b {
        color: #9f111c;
        font-size: 14px;
      }
    }
  }

  .Submit {
    margin: 70px 10px 30px;

    .submit_btn {
      background-color: #f7263c;
      border-radius: 5px;
      color: #fff;
      padding: 10px;
      width: 80%;
      margin: 0 auto;
    }

    & > p {
      width: 65%;
      margin: 0 auto;
      margin-top: 10px;
      color: #333333;
      font-size: 12px;
      line-height: 17px;
    }
  }
}
</style>
